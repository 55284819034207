.not-supported-message {
  font-size: 1.25rem;
  font-family: 'Rubik', sans-serif;;
  color: #EA640C;
  padding: 3.75rem 1.25rem 4.375rem;
}

canvas {
  height: 12.5rem;
  width: 12.5rem;
}