.win-copy {
  position: relative;
  width: 100%;
  max-width: 11.25rem;
  padding-bottom: 1rem;
}
@media only screen and (min-height: 750px) {
  .win-copy {
  padding-bottom: 6rem; 
}
}

.win-container {
  position: relative;
  top: -9.375rem;
  z-index: -1;
}

.win-img {
  position:fixed;
  top: 8vh;
  left: 0;
  width: 105%;
}

.win-card-container {
  position: relative;
  padding-top: 2.5rem;
  display: flex;
}

.win-card-value {
  font-family: 'Oswald', sans-serif ;
  font-size: 5rem;
  color: #FFFFFF;
  font-weight: 500;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 100%;
  height:fit-content;
  padding-top: 4rem;
  overflow: initial;
}

.win-card-wrapper {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  overflow: initial;
  z-index:10;
}

.win-card {
  width: 60vw;
}

.win-prize-copy {
  background-image: url('../../assets/voucher-bg-tile@1x.png');
  height: 4.125rem;
  width: 100%;
}

.win-prize-copy  p{ 
  font-family: 'Rubik', sans-serif;;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 120%;
  text-align: center;
}

.win-prize-copy__header {
  color: #FFFFFF;
  padding-top: 0.9375rem;
}

.win-description{
    min-width: 55%;
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    display: inline-block;
    color: #FFFFFF;
    background-color: #000000;
    opacity: 0.7;
    padding: 0.5rem 0.875rem;
    letter-spacing: -0.0563rem;
}

.win-prize-copy__description {
  color:  #EA640C;
}

.win-action-text {
  padding-bottom: 1rem;
}

.win-action-terms-conditions-text {
  padding-bottom: 1.5rem;
}

.win-action-terms-conditions-code{
  font-size: 0.75rem;
}

.win-action-terms-conditions-description-big{
  font-size: 1rem;
  height: fit-content;
}

.win-action-terms-conditions-description{
  font-size: 0.875rem;
  height: fit-content;
}

.win-code-coupon {
  display: inline-block;
  border: 1px solid #494B52;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: 'Rubik', sans-serif;;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 120%;
  color: #494B52;
  padding: 0.5rem 1rem;
  transition: background 500ms;
  margin: 0.4rem;
}

.win-code-coupon svg {
  margin-left: 0.9375rem;  
}

.copied {
  background-color: white;
  color: black;
}

.copied svg{
  stroke: black;
}

.win-footer {
  opacity: 0;
  position: absolute;
  bottom: -1rem;
  height: 40vh;
  transition: opacity 0.5s ease 0.5s;
}

.show-footer {
  opacity: 1;
}


@media only screen and (max-width: 320px) {
  /* For mobile phones: */
  .win-copy {
    width: 80%;
  }

  .win-prize-copy {
    font-size: 0.75rem;
  }

  .win-code-coupon  {
    margin-bottom: 0.625rem;
    margin-top: -0.9375rem;
  }

  .win-action-terms-conditions-text {
    padding-bottom: 0.625rem;
    
  }

  .win-action-text {
    padding-bottom: 0.5rem;
    
  }

  .code-coupon {
    font-size: 1.125rem;
    padding: 0.25rem 0.5rem;
  }
}