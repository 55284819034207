/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  overflow-x: hidden;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
  user-select: none;
  overflow-x: hidden;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.App {
  background-image: url('./assets/background_bottom.png'), url('./assets/background_top.png');
  background-color: rgba(0, 0, 0, 0.455);
  height: 100vh;
  text-align: center;
  overflow: hidden;
  background-position: -25vw 33vh, 0px -35vh;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-position 1.5s cubic-bezier(0.31, 0.23, 0.47, 1.32) 0.5s; 
}

.revealed {
  background-position: -25vw 42vh, 0px -65vh;
}
.no-user-container {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
position:absolute;
top: 70%;
left:50%;
transform: translate(-50%,-50%);
}

.mcxsp-logo {
  padding: 0.9375rem 0 1rem;
}

.action-image {
  width: 100%;
  max-width: 11.25rem;
  padding-bottom: 1rem;
}

.description-text {
  color: #FFFFFF;
  font-family: 'Rubik', sans-serif;;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 120%;
  text-align: center;
  width: 29ch;
  margin: 0 auto;
}

.content-container {
  overflow: hidden;
  z-index: -1;
}

.description-text__highlight {
  color:  #EA640C;
}

.scrath-canvas {
  margin-top: 15vh;
  padding-bottom: 1.5rem;
}

.action-text {
  color: black;
  font-family: 'Rubik', sans-serif;;
  font-size: 0.875rem;
  line-height: 120%;
  text-align: center;
  padding-bottom: 0.4375rem;
}

.terms-conditions-text {
  color: black;
  font-family: 'Rubik', sans-serif;;
  font-size: 0.625rem;
  line-height: 1.25rem;
  text-align: center;
  text-decoration: none;
  max-width: 90%;
  margin: 0 auto;
}

/* React-transition-group classes which handle the transition between views */
.fade-enter {
  opacity: 0;
  transform: scale(2);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 150ms, transform 150ms;
}


@media only screen and (max-width: 320px) {
  /* For mobile phones: */

  .mcxsp-logo {
    padding: 0.625rem 0 0.3125rem;
    width: 20%;
  }

  .action-image {
    width: 80%;
    padding-bottom: 0.25rem;
  }

  .action-text {
    font-size: 0.625rem;
    padding-bottom: 0.25rem;
  }

  .description-text {
    font-size: 0.7rem;
    padding: 0 1.25rem 0.9375rem;
  }
}
